import { useEffect, useRef, useState } from 'react';

const useWebSocket = (token) => {
  const socket = useRef(null);
  const [socketInstance, setSocketInstance] = useState(null);

  useEffect(() => {
   
    if (token) {
      socket.current = new WebSocket(`wss://techdash-api.uneinternet.com.br/ws/ws?token=${token}`);

      socket.current.onopen = () => {
        console.log('WebSocket Connected');
        // Send initial message to get OLT info
        socket.current.send(JSON.stringify({ action: 'get_olts_info' }));
        // Send initial message to get Splitters info
        socket.current.send(JSON.stringify({ action: 'get_all_splitters' }));
        // Set the socket instance  
        setSocketInstance(socket.current); 
      };

      socket.current.onclose = () => {
        console.log('WebSocket Disconnected');
        setSocketInstance(null); // Clear the socket instance
      };

      socket.current.onerror = (error) => {
        console.log('WebSocket Error:', error);
        setSocketInstance(null); // Clear the socket instance on error
      };

      return () => {
        if (socket.current) {
          socket.current.close();
        }
      };
    }
  }, [token]);

  return socketInstance
};

export default useWebSocket;
