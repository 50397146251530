import React, { useEffect, useState } from 'react';
import { useMap, AdvancedMarker } from '@vis.gl/react-google-maps';
import { Polyline } from './Polyline'; // Importando o componente Polyline criado
import useStore from '../../states/useStore';
import { getHouseIcon, getPoleIcon } from '../../utils/icons'; // Importando os ícones personalizados
import { Tooltip as ReactTooltip } from 'react-tooltip'; // Corrigindo a importação

const OnuMarkers = () => {
  const map = useMap();
  const { onus, splitters } = useStore(); 
  const [markers, setMarkers] = useState({});

  useEffect(() => {
    if (map) {
      const newMarkers = {};
      onus.forEach((onu) => {
        const splitter = findSplitter(onu);
        
        const houseColor = getHouseColor(onu.v_status);
        const roofColor = getRoofColor(onu.status);

        newMarkers[onu.id] = {
          position: { lat: parseFloat(onu.lat), lng: parseFloat(onu.lng) },
          content: `
            <div style="text-align: center;">
              <div>${onu.contract_id}</div> 
              ${getHouseIcon(houseColor, roofColor)}
            </div>`,
          tooltipData: `
            <strong>Contract ID:</strong> ${onu.contract_id} <br/>
            <strong>Username:</strong> <a href="https://erp.uneinternet.com.br/authentication_contracts/authentications_list/${onu.username}" target="_blank">${onu.username}</a><br/>
            <strong>Nome:</strong> ${onu.nome} <br/>
            <strong>Splitter ID:</strong> ${onu.splitter_id} <br/>
            <strong>Splitter Name:</strong> ${onu.splitter_name} <br/>
            <strong>Codemaps Splitter ID:</strong> ${onu.codemaps_splitter_id} <br/>
            <strong>Codemaps Splitter Name:</strong> ${onu.codemaps_splitter_name} <br/>
            <strong>Install Protocol:</strong> <a href="https://erp.uneinternet.com.br/solicitation_treatments/index/${onu.install_protocol_ass_id}" target="_blank">${onu.install_protocol_ass_id}</a><br/>
            <strong>Address:</strong> ${onu.street}, ${onu.street_number}, ${onu.neighborhood} <br/>
            <strong>Stage:</strong> ${onu.v_stage} <br/>
            <strong>Status:</strong> ${onu.status}
          `,
        };
      });

      // Filtrar splitters antes de adicionar os marcadores
      filterSplitters().forEach((splitter) => {
        newMarkers[splitter.splitter_id] = {
          position: { lat: parseFloat(splitter.lat), lng: parseFloat(splitter.lng) },
          content: `
            <div style="text-align: center;">
              <div>${splitter.splitter_name}</div> 
              ⬛
            </div>`,
          tooltipData: `Splitter ID: ${splitter.splitter_id}, Nome: ${splitter.splitter_name}`,
        };
      });

      setMarkers(newMarkers);
    }
  }, [onus, splitters, map]);

  // Função para encontrar o splitter correspondente ao ONU
  const findSplitter = (onu) => {
    let splitterEncontrado = splitters.find(
      (splitter) => splitter.splitter_id === onu.splitter_id
    );
    if (!splitterEncontrado) {
      splitterEncontrado = splitters.find(
        (splitter) => splitter.integrated_splitter_id === onu.codemaps_splitter_id
      );
    }
    return splitterEncontrado;
  };

  // Função para filtrar os splitters com base nos onus
  const filterSplitters = () => {
    return splitters.filter((splitter) =>
      onus.some(
        (onu) =>
          onu.splitter_id === splitter.splitter_id ||
          onu.codemaps_splitter_id === splitter.integrated_splitter_id
      )
    );
  };

  // Função para calcular a cor da casa com base no v_status do ONU
  const getHouseColor = (v_status) => {
    return v_status === 'Normal' || v_status === 'Cortesia' ? '#0000FF' : '#FF00FF';
  };

  // Função para calcular a cor do telhado com base no status
  const getRoofColor = (status) => {
    return status === 'online' ? '#008000' : '#FF0000';
  };

  return (
    <>
      {/* Renderizar os marcadores */}
      {Object.keys(markers).map((key) => {
        const marker = markers[key];
        return (
          <div key={key}>
            <AdvancedMarker
              position={marker.position}
              clickable={true}
              data-tooltip-id={`tooltip-${key}`}
            >    {/* Adiciona o estilo de centralização no ícone */}
            <div
              style={{
                position: 'relative',
                transform: 'translate(-50%, -100%)', // Centraliza o ícone
              }}
              dangerouslySetInnerHTML={{ __html: marker.content }}
            />
            </AdvancedMarker>
            {/* Tooltip para exibir as informações do marcador */}
            <ReactTooltip id={`tooltip-${key}`} place="top" effect="solid" html={true}>
              {marker.tooltipData}
            </ReactTooltip>
          </div>
        );
      })}

      {/* Renderizar as polylines */}
      {onus.map((onu) => {
        const splitter = findSplitter(onu);
        if (splitter) {
          return (
            <Polyline
              key={`line-${onu.id}-${splitter.splitter_id}`}
              path={[
                { lat: parseFloat(onu.lat), lng: parseFloat(onu.lng) },
                { lat: parseFloat(splitter.lat), lng: parseFloat(splitter.lng) },
              ]}
              strokeColor={onu.splitter_id ? '#0000FF' : '#FF0000'}
              strokeOpacity={1.0}
              strokeWeight={2}
              icons={onu.splitter_id ? [] : [{ icon: { path: 'M 0,-1 0,1', strokeOpacity: 1 }, offset: '0', repeat: '10px' }]} // Linha tracejada para codemaps_splitter_id
            />
          );
        }
        return null;
      })}
    </>
  );
};

export default OnuMarkers;