import axios from 'axios';

const API_URL = 'https://techdash-api.uneinternet.com.br/api/token'; // Substitua pela URL da sua API

const login = async (username, password) => {
  if (!username || !password) {
    throw new Error('Username e password são obrigatórios');
  }

  try {
    console.log('Tentando fazer login com username e password');
    const response = await axios.post(`${API_URL}/auth`, { username, password });

    if (response.status === 200 && response.data.access_token) {
      console.log('Login realizado com sucesso:', response.data.access_token);
      return { access_token: response.data.access_token }; // Retorna o token como parte de um objeto
    } else {
      console.error('Resposta inesperada do servidor:', response);
      throw new Error('Erro inesperado ao fazer login. Por favor, tente novamente.');
    }
  } catch (error) {
    if (error.response) {
      console.error('Erro ao fazer login:', error.response.data);
      throw new Error(`Erro ao fazer login: ${error.response.data.message || error.response.statusText}`);
    } else if (error.request) {
      console.error('Nenhuma resposta recebida:', error.request);
      throw new Error('Erro ao fazer login: Nenhuma resposta do servidor. Por favor, verifique sua conexão de internet.');
    } else {
      console.error('Erro ao configurar a requisição:', error.message);
      throw new Error(`Erro ao fazer login: ${error.message}`);
    }
  }
};

export { login };