import React from 'react';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';

const SidebarMenu = () => {
  return (
    <Menu theme="dark" mode="inline" className="sidebar-menu">
      <Menu.Item key="1">
        <Link to="/main">Main Table</Link>
      </Menu.Item>
      <Menu.Item key="2">
        <Link to="/hello">Hello World</Link>
      </Menu.Item>
    </Menu>
  );
};

export default SidebarMenu;