import React from 'react';
import { Layout } from 'antd';
import { Route, Routes } from 'react-router-dom';
import SidebarMenu from './SidebarMenu';
import MainTable from '../Home/MainTable';
import HelloWorld from '../Home/HelloWorld';
import AppHeader from '../common/Header';

const { Content, Sider } = Layout;

const AppLayout = () => {
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider>
        <SidebarMenu />
      </Sider>
      <Layout>
        <AppHeader />
        <Content style={{ margin: '16px' }}>
          <Routes>
            <Route path="main" element={<MainTable />} />
            <Route path="hello" element={<HelloWorld />} />
          </Routes>
        </Content>
      </Layout>
    </Layout>
  );
};

export default AppLayout;
