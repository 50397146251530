import React, { useEffect, useState, useContext, useRef } from 'react';
import { Table, Input, Button, Space, Tag, message, Badge, Popover } from 'antd';
import { SearchOutlined, ToolTwoTone, WarningTwoTone  } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { AuthContext } from '../../context/AuthContext';
import useWebSocket from '../../hooks/useWebSocket';
import useStore from '../../states/useStore';
import FiltersRow from './FiltersRow';
import MapModal from './MapModal'; // Importando o componente MapModal

const MainTable = () => {
  const { authToken } = useContext(AuthContext);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  const [oltId, setOltId] = useState(null);
  const [slotTreeValue, setSlotTreeValue] = useState([]);
  const [splitterTreeValue, setSplitterTreeValue] = useState([]);
  const [previousSlotTreeValue, setPreviousSlotTreeValue] = useState([]);
  const [previousSplitterTreeValue, setPreviousSplitterTreeValue] = useState([]);
  const [isSignalColumnVisible, setIsSignalColumnVisible] = useState(false);
  const [mapModalVisible, setMapModalVisible] = useState(false);
  const [pendingUpdates, setPendingUpdates] = useState(0); // Estado para rastrear atualizações pendentes

  const socket = useWebSocket(authToken);
  const { olts, onus, splitters, handleWebSocketMessage } = useStore();
  
  const [messageApi, contextHolder] = message.useMessage();
  const loadingMessageKey = useRef('loading-update'); // Chave única para a mensagem de loading

  // Função para abrir o modal de mapa
  const handleMapClick = () => {
    setMapModalVisible(true);
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const onOltChange = (value) => {
    setOltId(value);
  };

  const onSlotTreeChange = (newValue) => {
    console.log("onSlotTreeChange: ", newValue);
    setSlotTreeValue(newValue.map(val => JSON.parse(val)));
  };

  const onSplitterTreeChange = (newValue) => {
    console.log("onSplitterTreeChange: ", newValue);
    setSplitterTreeValue(newValue);
  };

  const handleDropdownVisibleChange = (open) => {
    if (!open && JSON.stringify(slotTreeValue) !== JSON.stringify(previousSlotTreeValue)) {
      setPreviousSlotTreeValue(slotTreeValue);
      handleUpdateClick();
    }
  };

  const callWS = (action) => {
    if (!oltId || slotTreeValue.length === 0) {
      console.log("Please select both OLT and Slot/Port.");
      return;
    }
  
    const message = {
      action: action,
      data: {
        olt_id: oltId,
        slots_ports: slotTreeValue.filter(v => v.port !== null),
      }
    };
  
    useStore.getState().incrementPendingUpdates(messageApi); // Passa messageApi como argumento
  
    socket.send(JSON.stringify(message));
  };

  const handleUpdateClick = async () => {
    callWS("get_onu_desc");
    callWS("get_onu_status");
    callWS("get_onu_down_cause");
    callWS("get_onu_serial");
  };

  const handleUpdateButtonClick = async () => {
    callWS("get_onu_status");
    callWS("get_onu_down_cause");
  };

  const handleGetSignal = async () => {
    callWS("get_onu_signal");
    setIsSignalColumnVisible(true);
  };

  useEffect(() => {
    if (socket) {
      socket.onmessage = (event) => {
        const parsedMessage = event.data;
        handleWebSocketMessage(parsedMessage, socket, messageApi); // Passa messageApi como argumento
      };
    }
  }, [socket, handleWebSocketMessage, messageApi]);

  const filteredData = onus.filter(onu => 
    slotTreeValue.some(value => 
      onu.slot === value.slot && onu.port === value.port
    )
  );

  const columns = [
    { 
      title: 'Status', 
      dataIndex: 'status', 
      key: 'status', 
      ...getColumnSearchProps('status'),
      render: (text, record) => {
        const displayText = text === 'online' ? text : (record.down_cause && record.down_cause !== 'undef' ? record.down_cause : text);
        return (
          <Tag color={text === 'online' ? 'green' : 'red'}>
            {displayText ? displayText.toUpperCase() : ''}
          </Tag>
        );
      },
      width: 'auto',
    },
    { 
      title: 'Serial', 
      dataIndex: 'serial', 
      key: 'serial', 
      ...getColumnSearchProps('serial'),
      width: 'auto',
    },
    {
      title: 'Nome',
      dataIndex: 'nome',
      key: 'nome',
      ...getColumnSearchProps('nome'),
      ellipsis: true,
      render: (text, record) => {
        const displayText = text || record.description;
        return displayText.length > 40 ? `${displayText.substring(0, 40)}...` : displayText;
      },
      width: 'auto',
    },
    {
      title: 'Contrato',
      dataIndex: 'v_status',
      key: 'v_status',
      ...getColumnSearchProps('v_status'),
      render: (text, record) => {
        let color = 'magenta';
        if (text === 'Normal' || text === 'Cortesia') {
          color = 'blue';
        }
    
        return (
          <Space>
            <Tag color={color}>
              {text ? text.toUpperCase() : 'NÃO CADASTRADO'}
            </Tag>
            {record.install_protocol_ass_id && (
              <a href={`https://erp.uneinternet.com.br/solicitation_treatments/index/${record.install_protocol_ass_id}`} target="_blank" rel="noopener noreferrer">
                <ToolTwoTone />
              </a>
            )}
            {record.address_change_ass_id && (
              <a href={`https://erp.uneinternet.com.br/solicitation_treatments/index/${record.address_change_ass_id}`} target="_blank" rel="noopener noreferrer">
                <WarningTwoTone />
              </a>
            )}
          </Space>
        );
      },
      width: 'auto',
    },
    {
      title: 'CTO',
      dataIndex: 'splitter_name',
      key: 'splitter_name',
      ...getColumnSearchProps('splitter_name'),
      width: 'auto',
      render: (text, record) => (
        <Space>
          {text && text !== record.codemaps_splitter_name ? (
            <Popover content={record.codemaps_splitter_name} title="Codemaps Splitter" trigger="click">
              <span style={{ cursor: 'pointer', textDecoration: 'underline' }}>{text}</span>
            </Popover>
          ) : (
            text || <span style={{ color: 'red' }}>{record.codemaps_splitter_name}</span>
          )}
          {text && text !== record.codemaps_splitter_name && <Badge status="warning" />}
        </Space>
      ),
    },
    { 
      title: 'Sinal', 
      dataIndex: 'sinal', 
      key: 'sinal', 
      hidden: !isSignalColumnVisible,
      ...getColumnSearchProps('sinal'),
      width: 'auto',
    },
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
      ...getColumnSearchProps('username'),
      width: 'auto',
      render: (text) => (
        <a href={`https://erp.uneinternet.com.br/authentication_contracts/authentications_list/${text}`} target="_blank" rel="noopener noreferrer">
          {text}
        </a>
      ),
    },
    { title: 'OLT ID', dataIndex: 'olt_id', key: 'olt_id', hidden: true },
    { title: 'Slot', dataIndex: 'slot', key: 'slot', hidden: true },
    { title: 'Port', dataIndex: 'port', key: 'port', hidden: true },
  ];

  return (
    <div className="main-table-container">
      {contextHolder}
      <FiltersRow
        olts={olts}
        splitters={splitters}
        slotTreeValue={slotTreeValue}
        splitterTreeValue={splitterTreeValue}
        onOltChange={onOltChange}
        onSlotTreeChange={onSlotTreeChange}
        onSplitterTreeChange={onSplitterTreeChange}
        handleDropdownVisibleChange={handleDropdownVisibleChange}
        handleUpdateButtonClick={handleUpdateButtonClick}
        handleMapClick={handleMapClick} // Passa a função que abre o modal
        callWS={callWS}
        handleGetSignal={handleGetSignal}
      />
      <MapModal visible={mapModalVisible} setVisible={setMapModalVisible} /> {/* Passa o estado do modal */}
      <Table
        columns={columns.filter(col => !col.hidden)}
        dataSource={filteredData}
        rowKey={(record) => `${record.olt_id}-${record.slot}-${record.port}-${record.id}`}
        tableLayout="auto" // Define o layout como automático
        pagination={{
          pageSize: 150, // Define o número máximo de linhas por página
          showSizeChanger: true, // Exibe um seletor para alterar o número de linhas por página
          pageSizeOptions: ['50', '100', '150', '300'], // Opções no seletor de tamanhos de página
        }}
      />
    </div>
  );
};

export default MainTable;