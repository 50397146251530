
export const generateTreeData = () => {
  const slots = [];
  for (let i = 1; i <= 11; i++) {
    const children = [];
    for (let j = 0; j <= 15; j++) {
      children.push({
        title: `${i}/${j}`,
        value: JSON.stringify({ slot: i, port: j }),
        key: `slot${i}-port${j}`,
      });
    }
    slots.push({
      title: `Slot ${i}`,
      value: JSON.stringify({ slot: i, port: null }),
      key: `slot${i}`,
      children,
    });
  }
  return slots;
};

export const generateSplittersTreeData = (splitters) => {
  const treeData = [];
  const othersCategory = {
    title: 'Outros',
    key: 'outros',
    value: 'outros',
    selectable: false,
    children: [],
  };

  splitters.forEach(splitter => {
    const splitter_name = splitter.splitter_name.replace(/\s+/g, '').replace(/^BHZ-/, '');
    
    // Verifica se olt_title, slot_olt, e port_olt têm valores
    const hasOltInfo = splitter.olt_title && splitter.slot_olt && splitter.port_olt;
    const titleWithOlt = hasOltInfo
      ? `${splitter.olt_title} (${splitter.slot_olt} / ${splitter.port_olt})`
      : '';

    const nodeTitle = (
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <span>{splitter.splitter_name}</span>
        {hasOltInfo && (
          <span style={{ marginLeft: 'auto', color: 'gray' }}>{titleWithOlt}</span>
        )}
      </div>
    );

    if (splitter_name.split('-').length === 1) {
      othersCategory.children.push({
        title: nodeTitle,
        key: splitter.value,
        value: splitter.value,
        selectable: true,
        data: splitter,
      });
    } else {
      const parts = splitter_name.split('-');
      let currentLevel = treeData;

      parts.forEach((part, index) => {
        let existingNode = currentLevel.find(node => node.title === part);

        if (!existingNode) {
          existingNode = {
            title: part,
            key: parts.slice(0, index + 1).join('-'),
            children: [],
            selectable: false,
            value: part,
          };
          currentLevel.push(existingNode);
        }

        if (index === parts.length - 1) {
          existingNode.title = nodeTitle;
          existingNode.value = splitter.value;
          existingNode.selectable = true;
          existingNode.data = splitter;
        } else {
          currentLevel = existingNode.children || [];
        }
      });
    }
  });

  if (othersCategory.children.length > 0) {
    treeData.push(othersCategory);
  }

  const sortTree = (nodes) => {
    nodes.sort((a, b) => {
      const titleA = typeof a.title === 'string' ? a.title : '';
      const titleB = typeof b.title === 'string' ? b.title : '';
      return titleA.localeCompare(titleB);
    });
    nodes.forEach(node => {
      if (node.children && node.children.length > 0) {
        sortTree(node.children);
      }
    });
  };

  sortTree(treeData);

  treeData.sort((a, b) => (a.key === 'outros' ? 1 : b.key === 'outros' ? -1 : 0));
  
  return treeData;
};