import React, { useState, useContext } from 'react';
import { Form, Input, Button, Checkbox } from 'antd';
import { login } from '../../utils/auth';
import { AuthContext } from '../../context/AuthContext';

const Login = () => {
  const [loading, setLoading] = useState(false);
  const { handleLogin } = useContext(AuthContext);
  const [remember, setRemember] = useState(false);

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const { access_token } = await login(values.username, values.password);
      if (remember) {
        localStorage.setItem('authToken', access_token);
      } else {
        sessionStorage.setItem('authToken', access_token);
      }
      handleLogin(access_token);
    } catch (error) {
      console.error('Falha no login', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="login-container">
      <h2 className="login-title">Login</h2>
      <Form onFinish={onFinish} className="login-form">
        <Form.Item name="username" rules={[{ required: true, message: 'Please input your username!' }]} className="login-form-item">
          <Input placeholder="Username" autocomplete="username" />
        </Form.Item>
        <Form.Item name="password"  rules={[{ required: true, message: 'Please input your password!' }]} className="login-form-item">
          <Input.Password placeholder="Password" autocomplete="current-password"/>
        </Form.Item>
        <Form.Item className="login-remember">
          <Checkbox checked={remember} onChange={e => setRemember(e.target.checked)}>Lembrar senha</Checkbox>

        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading} className="login-button">
            Login
          </Button>
        </Form.Item>
      </Form>

    </div>
  );
};

export default Login;