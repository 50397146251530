import React, { useState } from 'react';
import { Modal } from 'antd';
import { APIProvider, Map } from '@vis.gl/react-google-maps';
import OnuMarkers from './OnuMarkers';

import useStore from '../../states/useStore'; // Importe o Zustand Store

const MapModal = ({ visible, setVisible }) => {
  const { isUserInfoLoaded, onus } = useStore(); 


  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Modal
      title="Mapa com Marcadores de ONUs"
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      width={1000}
      footer={null}
    >
      <div style={{ height: '600px' }}>
        <APIProvider apiKey={"AIzaSyBigdrOxnxqE2pm-N-QxC3Fv_-6dXcvwiQ"}>
          {isUserInfoLoaded ? (
            <Map
              defaultZoom={13}
              defaultCenter={{ lat: -19.9378, lng: -43.9627 }} // Coordenadas de exemplo
              //onCameraChanged={(ev) =>
              //  console.log('Camera mudou:', ev.detail.center, 'Zoom:', ev.detail.zoom)
              //}
              mapId="da37f3254c6a6d1c"
            >
              <OnuMarkers onus={onus} />

            </Map>
          ) : (
            <div>Carregando dados de usuário...</div> // Exibe algo enquanto UserInfo não está carregado
          )}
        </APIProvider>
      </div>
    </Modal>
  );
};

export default MapModal;