//usestore
import { create } from 'zustand';

const loadingMessageKey = 'loading-update';

const useStore = create((set, get) => ({
  olts: [],
  onus: [],
  splitters: [],
  isUserInfoLoaded: false, // Estado de carregamento do user info
  pendingUpdates: 0, // Estado para rastrear atualizações pendentes

  setOlts: (olts) => set({ olts }),
  setOnus: (onus) => set({ onus }),
  setSplitters: (splitters) => set({ splitters }),
  setIsUserInfoLoaded: (isLoaded) => {
    set((state) => ({
      isUserInfoLoaded: isLoaded,
    }));
  },

  incrementPendingUpdates: (messageApi) => set((state) => {
    console.log("Pending updates: ", state.pendingUpdates);
    if (state.pendingUpdates === 0) {
      messageApi.open({
        key: loadingMessageKey,
        type: 'loading',
        content: 'Atualizando dados...',
        duration: 0,
      });
    }
    return {
      pendingUpdates: state.pendingUpdates + 1,
    };
  }),

  decrementPendingUpdates: (messageApi) => {
    set((state) => {
      console.log("Pending updates: ", state.pendingUpdates);
      console.log("Is user info loaded: ", state.isUserInfoLoaded);
      const newPendingUpdates = state.pendingUpdates - 1;
      if (newPendingUpdates <= 0 && state.isUserInfoLoaded) {
        messageApi.open({
          key: loadingMessageKey,
          type: 'success',
          content: 'Atualização concluída com sucesso!',
          duration: 2,
        });
      // Marca que as informações do usuário estão carregadas
      return {
        pendingUpdates: 0,
        isUserInfoLoaded: true, // As informações foram completamente carregadas
      };
      }
      return { pendingUpdates: newPendingUpdates };
    });
  },

  callWSget_user_info: (socket) => {
    const onus = get().onus;

    if (!onus.length) {
      console.log("No ONUs available.");
      return;
    }

    const users = onus
      .map(onu => onu.username)
      .filter(username => username && !username.startsWith("Erro:"));

    if (!users.length) {
      console.log("No valid usernames available.");
      return;
    }

    const message = {
      action: "get_user_info",
      data: {
        usernames: users,
      }
    };

    set((state) => ({
      isUserInfoLoaded: false,
      pendingUpdates: state.pendingUpdates + 1,
    }));

    socket.send(JSON.stringify(message));
  },

  handleWebSocketMessage: (parsedMessage, socket, messageApi) => {
    parsedMessage = JSON.parse(parsedMessage);
    console.log(`WebSocket Message Received [Action: ${parsedMessage.action}]:`, parsedMessage);

    const { setOlts, setOnus, setSplitters, setIsUserInfoLoaded, decrementPendingUpdates } = get();

    // Processa a resposta para get_olts_info
    if (parsedMessage.action === 'get_olts_info' && parsedMessage.status === 'success') {
        setOlts(parsedMessage.data);
    }

    // Processa a resposta para get_onu_desc
    if (parsedMessage.action === 'get_onu_desc' && parsedMessage.status === 'success') {
        const currentOnus = get().onus;
        const onusData = parsedMessage.data.flat().map(({ olt_id, olt_name, slot, port, onus }) =>
            onus.map(onu => ({
                ...onu,
                olt_id,
                olt_name,
                slot,
                port,
            }))
        ).flat();

        const updatedOnus = [...currentOnus];

        onusData.forEach((newOnu) => {
            const index = updatedOnus.findIndex(onu =>
                onu.id === newOnu.id &&
                onu.olt_id === newOnu.olt_id &&
                onu.slot === newOnu.slot &&
                onu.port === newOnu.port
            );

            if (index !== -1) {
                updatedOnus[index] = { ...updatedOnus[index], ...newOnu };
            } else {
                updatedOnus.push(newOnu);
            }
        });

        set({ onus: updatedOnus });

        // Chama a função para enviar get_user_info após get_onu_desc ser processado
        get().callWSget_user_info(socket);
    }

    // Processa a resposta para get_user_info
    if (parsedMessage.action === 'get_user_info' && parsedMessage.status === 'success') {
        const currentOnus = get().onus;

        const userInfoData = parsedMessage.data.reduce((acc, userInfo) => {
            const username = userInfo.username;
            acc[username] = userInfo;
            return acc;
        }, {});

        const updatedOnus = [...currentOnus];

        updatedOnus.forEach((onu, index) => {
            if (userInfoData[onu.username]) {
                updatedOnus[index] = { ...updatedOnus[index], ...userInfoData[onu.username] };
            }
        });

        set({ onus: updatedOnus });
        setIsUserInfoLoaded(true);
        decrementPendingUpdates(messageApi); // Decrementa após processar a resposta
    }

    // Processa a resposta para get_onu_status
    if (parsedMessage.action === 'get_onu_status' && parsedMessage.status === 'success') {
        const currentOnus = get().onus;
        const statusData = parsedMessage.data.flat().map(({ olt_id, slot, port, onus }) =>
            onus.map(({ id, status }) => ({
                id,
                olt_id,
                slot,
                port,
                status,
            }))
        ).flat();

        const updatedOnus = [...currentOnus];

        statusData.forEach((newStatus) => {
            const index = updatedOnus.findIndex(onu =>
                onu.id === newStatus.id &&
                onu.olt_id === newStatus.olt_id &&
                onu.slot === newStatus.slot &&
                onu.port === newStatus.port
            );

            if (index !== -1) {
                updatedOnus[index] = { ...updatedOnus[index], status: newStatus.status };
            }
        });

        set({ onus: updatedOnus });
        decrementPendingUpdates(messageApi);
    }

    // Processa a resposta para get_onu_serial
    if (parsedMessage.action === 'get_onu_serial' && parsedMessage.status === 'success') {
        const currentOnus = get().onus;
        const serialData = parsedMessage.data.flat().map(({ olt_id, slot, port, onus }) =>
            onus.map(({ id, serial }) => ({
                id,
                olt_id,
                slot,
                port,
                serial,
            }))
        ).flat();

        const updatedOnus = [...currentOnus];

        serialData.forEach((newSerial) => {
            const index = updatedOnus.findIndex(onu =>
                onu.id === newSerial.id &&
                onu.olt_id === newSerial.olt_id &&
                onu.slot === newSerial.slot &&
                onu.port === newSerial.port
            );

            if (index !== -1) {
                updatedOnus[index] = { ...updatedOnus[index], serial: newSerial.serial };
            }
        });

        set({ onus: updatedOnus });
        decrementPendingUpdates(messageApi);
    }

    // Processa a resposta para get_onu_down_cause
    if (parsedMessage.action === 'get_onu_down_cause' && parsedMessage.status === 'success') {
        const currentOnus = get().onus;
        const downCauseData = parsedMessage.data.flat().map(({ olt_id, slot, port, onus }) =>
            onus.map(({ id, down_cause }) => ({
                id,
                olt_id,
                slot,
                port,
                down_cause,
            }))
        ).flat();

        const updatedOnus = [...currentOnus];

        downCauseData.forEach((newCause) => {
            const index = updatedOnus.findIndex(onu =>
                onu.id === newCause.id &&
                onu.olt_id === newCause.olt_id &&
                onu.slot === newCause.slot &&
                onu.port === newCause.port
            );

            if (index !== -1) {
                updatedOnus[index] = { ...updatedOnus[index], down_cause: newCause.down_cause };
            } else {
                updatedOnus.push(newCause);
            }
        });

        set({ onus: updatedOnus });
        decrementPendingUpdates(messageApi);
    }

    // Processa a resposta para get_all_splitters
    if (parsedMessage.action === 'get_all_splitters' && parsedMessage.status === 'success') {
        const splittersData = parsedMessage.data.map(splitter => ({
            splitter_id: splitter.splitter_id,
            integrated_splitter_id: parseInt(splitter.integrated_splitter_id) || null,
            splitter_name: splitter.splitter_name,
            value: splitter.integration_code,
            olt_title: splitter.olt_title,
            slot_olt: splitter.slot_olt,
            port_olt: splitter.port_olt,
            smartolt_olt_id: splitter.smartolt_olt_id,
            lat: splitter.lat,
            lng: splitter.lng,
            out_ports: splitter.out_ports,
        }));
        setSplitters(splittersData);
        decrementPendingUpdates(messageApi);
    }
}
  }));

export default useStore;