import React, { createContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { jwtDecode as decode } from "jwt-decode"

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [authToken, setAuthToken] = useState(localStorage.getItem('authToken'));
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    if (token) {
      try {
        const decoded = decode(token);
        if (decoded.exp * 1000 > Date.now()) {
          setAuthToken(token);
          //console.log('Token válido, usuário autenticado');
        } else {
          console.log('Token expirado');
          localStorage.removeItem('authToken');
          setAuthToken(null);
          navigate('/login');
        }
      } catch (error) {
        console.error('Erro ao decodificar token:', error);
        localStorage.removeItem('authToken');
        setAuthToken(null);
        navigate('/login');
      }
    } else {
      navigate('/login');
    }
  }, [navigate]);

  const handleLogin = (token) => {
    try {
      setAuthToken(token);
      localStorage.setItem('authToken', token);
      navigate('/main');
    } catch (error) {
      console.error('Erro ao fazer login:', error);
    }
  };

  const logout = () => {
    try {
      setAuthToken(null);
      localStorage.removeItem('authToken');
      navigate('/login');
    } catch (error) {
      console.error('Erro ao fazer logout:', error);
    }
  };

  return (
    <AuthContext.Provider value={{ authToken, handleLogin, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
