import React, { useMemo } from 'react';
import { Row, Col, Select, TreeSelect, Input, Button } from 'antd';
import { ReloadOutlined, SignalFilled, EnvironmentTwoTone } from '@ant-design/icons';
import { generateTreeData, generateSplittersTreeData } from '../../utils/treeDataHelpers';

const { Option } = Select;
const { SHOW_PARENT } = TreeSelect;

const FiltersRow = ({
  olts,
  splitters,
  slotTreeValue,
  splitterTreeValue,
  onOltChange,
  onSlotTreeChange,
  onSplitterTreeChange,
  handleDropdownVisibleChange,
  handleUpdateButtonClick,
  handleMapClick,
  callWS,
  handleGetSignal
}) => {
  const slotTreeData = useMemo(() => generateTreeData(), []);
  const splitterTreeData = useMemo(() => {
    //console.log('Splitters:', splitters); // Verifique os dados dos splitters
    const treeData = generateSplittersTreeData(splitters);
    //console.log('Splitter Tree Data:', treeData); // Verifique os dados gerados para a TreeSelect
    return treeData;
  }, [splitters]);
  
  return (
    <Row gutter={[16, 16]} className="filters-row">
      <Col span={3}>
        <Select
          placeholder="OLT"
          className="filter-select filter-olt"
          onChange={onOltChange}
        >
          {olts.map((olt) => (
            <Option key={olt.id} value={olt.id}>{olt.desc}</Option>
          ))}
        </Select>
      </Col>
      <Col span={3}>
        <TreeSelect
          treeData={slotTreeData}
          value={slotTreeValue.map(v => JSON.stringify(v))}
          onChange={onSlotTreeChange}
          onDropdownVisibleChange={handleDropdownVisibleChange}
          treeCheckable={true}
          showCheckedStrategy={SHOW_PARENT}
          placeholder="Slot/Port"
          style={{ width: '100%' }}
        />
      </Col>
      <Col span={9}>
        <TreeSelect
          treeData={splitterTreeData}
          value={splitterTreeValue}
          onChange={onSplitterTreeChange}
          placeholder="Select Splitters"
          style={{ width: '100%' }}
          treeDefaultExpandAll={false}
          dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
        />
      </Col>
      <Col span={6}>
        <Button
          type="primary"
          className="filter-button"
          icon={<ReloadOutlined />}
          onClick={handleUpdateButtonClick}
        />
        <Button
          className="filter-button codemaps-button"
          icon={<EnvironmentTwoTone/>}
          onClick={handleMapClick}
        />
        <Button
          className="filter-button"
          icon={<SignalFilled />}
          onClick={handleGetSignal}
        />
      </Col>
    </Row>
  );
};

export default FiltersRow;