// Função para retornar o SVG da casa com as cores dinamicamente aplicadas
export const getHouseIcon = (houseColor = '#000000', roofColor = '#000000') => {
    return `
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 495.398 495.398" width="24" height="24">
        <g id="house" fill="${houseColor}">
          <path d="M487.083,225.514l-75.08-75.08V63.704c0-15.682-12.708-28.391-28.413-28.391
          c-15.669,0-28.377,12.709-28.377,28.391v29.941L299.31,37.74
          c-27.639-27.624-75.694-27.575-103.27,0.05L8.312,225.514c-11.082,11.104-11.082,29.071,0,40.158
          c11.087,11.101,29.089,11.101,40.172,0l187.71-187.729c6.115-6.083,16.893-6.083,22.976-0.018
          l187.742,187.747c5.567,5.551,12.825,8.312,20.081,8.312
          c7.271,0,14.541-2.764,20.091-8.312C498.17,254.586,498.17,236.619,487.083,225.514z"/>
        </g>
        <g id="roof" fill="${roofColor}">
          <path d="M257.561,131.836c-5.454-5.451-14.285-5.451-19.723,0L72.712,296.913
          c-2.607,2.606-4.085,6.164-4.085,9.877v120.401c0,28.253,22.908,51.16,51.16,51.16h81.754v-126.61h92.299v126.61h81.755
          c28.251,0,51.159-22.907,51.159-51.159V306.79c0-3.713-1.465-7.271-4.085-9.877L257.561,131.836z"/>
        </g>
      </svg>
    `;
  };
  
  // Função para retornar o SVG do poste
  export const getPoleIcon = () => {
    return `
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 495.398 495.398" width="40" height="40">
            <path fill="#000000" opacity="1.000000" stroke="none" 
                d="
            M670.386841,152.336716 
                C673.724304,153.114120 676.660278,153.709167 679.525330,154.546951 
                C681.844360,155.225067 683.958618,156.474701 684.124207,159.197098 
                C684.312012,162.283508 681.866638,163.415924 679.532410,164.402115 
                C678.033081,165.035568 676.434509,165.434174 674.505554,166.059601 
                C676.728821,166.974091 678.690430,167.649765 680.527100,168.573883 
                C682.309753,169.470825 684.170959,170.508606 684.156799,172.909836 
                C684.141541,175.500916 682.208313,176.646194 680.209778,177.565002 
                C678.705322,178.256699 677.126038,178.917084 675.511108,179.206451 
                C673.052612,179.646973 672.224731,181.006607 672.187195,183.360901 
                C672.149719,185.716614 673.406433,186.308060 675.437073,186.900345 
                C686.051636,189.996277 690.744995,196.904938 689.216614,206.793671 
                C687.955811,214.951065 680.511780,220.274078 670.281921,220.277618 
                C645.948975,220.286041 621.615906,220.319199 597.283386,220.216324 
                C593.903992,220.202042 591.235962,220.995422 588.793274,223.452530 
                C563.652161,248.741623 538.473267,273.993500 513.195312,299.145569 
                C510.379547,301.947327 509.202332,304.816437 509.205475,308.803284 
                C509.299683,428.134644 509.300140,547.466064 509.235718,666.797424 
                C509.233673,670.566772 510.093445,673.090515 513.368286,675.565002 
                C522.536438,682.492554 527.235657,692.114868 527.265808,703.620117 
                C527.378113,746.452454 527.247131,789.285522 527.412842,832.117554 
                C527.431213,836.859009 526.553589,838.630432 521.246826,838.728577 
                C500.253845,839.116760 479.270111,838.721252 458.282928,838.677673 
                C455.751190,838.672363 454.643280,837.467346 454.712280,834.995178 
                C454.749451,833.663330 454.691864,832.329041 454.691925,830.995850 
                C454.693390,789.163208 454.724091,747.330505 454.683197,705.497864 
                C454.670807,692.846558 459.029205,682.285645 469.535004,674.923340 
                C473.017212,672.482971 472.692535,669.420715 472.692719,666.142700 
                C472.698212,556.811096 472.695160,447.479523 472.693359,338.147949 
                C472.693207,328.148102 472.582672,318.146393 472.754089,308.149475 
                C472.812775,304.726746 471.814484,302.186401 469.367035,299.750153 
                C443.854767,274.354309 418.412354,248.888153 393.025635,223.366791 
                C390.706696,221.035568 388.229279,220.214355 385.022675,220.225754 
                C360.190063,220.314011 335.356842,220.328613 310.524139,220.258408 
                C299.046753,220.225967 291.966553,212.696075 292.692261,201.591919 
                C293.161407,194.413284 297.745972,189.208786 305.728058,187.061905 
                C307.881439,186.482727 309.977661,186.394516 309.861420,183.254471 
                C309.756775,180.425888 308.170563,179.511108 305.792603,179.166351 
                C305.629608,179.142731 305.466949,179.089066 305.314484,179.024872 
                C302.309509,177.759766 298.217926,177.279434 297.976501,173.230927 
                C297.725159,169.014908 301.791138,168.357727 304.789551,167.067947 
                C305.243439,166.872696 305.695343,166.672913 306.715759,166.227432 
                C304.693634,165.428177 303.043518,164.874725 301.482086,164.134232 
                C299.447235,163.169250 297.736481,161.810867 297.805115,159.272400 
                C297.868317,156.934891 299.544067,155.670181 301.434204,154.929321 
                C311.281219,151.069748 321.231903,150.765320 331.170288,154.556198 
                C333.356720,155.390167 335.698120,156.647751 335.756165,159.326920 
                C335.820007,162.272552 333.321655,163.447037 330.994202,164.475739 
                C329.655487,165.067429 328.270599,165.554672 326.658234,166.186172 
                C330.354950,167.954514 335.755829,168.206436 335.541351,173.073517 
                C335.331299,177.841782 330.326691,178.472015 326.459015,179.352066 
                C323.990906,179.913651 323.250031,181.347168 323.377838,183.669052 
                C323.558624,186.953522 325.956177,186.632385 328.097107,186.635757 
                C346.763397,186.665161 365.429688,186.666351 384.096008,186.675766 
                C390.929230,186.679214 397.763000,186.732910 404.595276,186.653778 
                C406.582642,186.630753 409.207764,187.483353 409.494263,184.031555 
                C409.725677,181.243515 408.653534,179.875671 405.795929,179.160538 
                C402.200195,178.260696 397.312988,177.689438 397.239227,172.945831 
                C397.171478,168.589737 402.021790,168.057938 405.179321,166.397461 
                C404.952606,166.151276 404.782776,165.826965 404.518463,165.701248 
                C401.434082,164.233948 396.969635,163.556000 397.195099,159.289322 
                C397.431946,154.807404 401.991211,154.244324 405.456421,153.335220 
                C413.191498,151.305862 420.967255,151.374649 428.605255,153.873123 
                C431.424530,154.795319 434.811646,155.710327 435.030426,159.322739 
                C435.262177,163.149841 431.601105,163.743240 428.999084,165.039322 
                C428.289551,165.392731 427.483215,165.551727 425.824158,166.092209 
                C427.921692,166.945328 429.269745,167.378021 430.507172,168.022934 
                C432.570648,169.098373 434.957794,170.134186 435.020325,172.876328 
                C435.089905,175.925125 432.473022,176.948120 430.243774,178.026245 
                C428.910187,178.671188 427.421082,179.017517 425.977081,179.405960 
                C423.555817,180.057312 422.990570,181.760071 423.163544,183.997726 
                C423.364746,186.601517 425.210571,186.654205 427.125885,186.654739 
                C440.958923,186.658676 454.792206,186.625732 468.624878,186.698822 
                C471.681244,186.714966 472.734802,185.329590 472.701141,182.434433 
                C472.623749,175.768936 472.622986,169.100647 472.722076,162.435440 
                C472.889069,151.204163 480.409760,143.542984 491.062195,143.627579 
                C501.709686,143.712128 509.091675,151.379608 509.245117,162.631088 
                C509.333771,169.129852 509.362000,175.632339 509.239075,182.129776 
                C509.181519,185.172134 509.992462,186.743744 513.423828,186.715485 
                C527.255920,186.601562 541.089600,186.677597 554.922668,186.680344 
                C556.841309,186.680725 558.691406,186.599869 558.837097,183.980621 
                C558.959717,181.775696 558.483215,179.984940 556.012695,179.400467 
                C555.850464,179.362076 555.683594,179.338470 555.526184,179.285980 
                C552.087708,178.139175 547.473328,177.609680 547.148376,173.328903 
                C546.777954,168.450134 552.066956,168.282608 555.474792,166.188843 
                C554.435364,165.742569 553.533691,165.418381 552.690308,164.981674 
                C550.093018,163.636902 546.522278,162.870270 546.993835,158.994507 
                C547.428711,155.420929 550.842468,154.621582 553.650574,153.779648 
                C561.827820,151.327911 570.085266,151.375473 578.269470,153.801514 
                C581.132568,154.650223 584.523315,155.550766 584.841980,159.096527 
                C585.177063,162.825500 581.686157,163.722977 579.026367,165.013504 
                C578.299500,165.366211 577.560913,165.694824 576.293213,166.282104 
                C577.972961,166.986526 579.179016,167.402420 580.300720,167.981766 
                C582.526245,169.131165 585.106384,170.251755 584.937622,173.250381 
                C584.787415,175.918442 582.398254,177.012100 580.268738,177.980499 
                C578.918396,178.594559 577.447815,179.030823 575.985657,179.293365 
                C573.322876,179.771454 572.481995,181.256027 572.614136,183.880371 
                C572.771423,187.003738 574.889771,186.651825 576.860474,186.654770 
                C591.026855,186.675964 605.193237,186.673737 619.359619,186.677856 
                C630.692749,186.681137 642.026001,186.703018 653.359009,186.668198 
                C655.605469,186.661301 658.311218,187.244522 658.594482,183.684799 
                C658.835510,180.656189 657.022888,179.557617 654.409485,179.172134 
                C653.919128,179.099823 653.433228,178.944702 652.966980,178.769928 
                C649.993225,177.655212 646.439575,176.724701 646.287537,172.938553 
                C646.151489,169.552383 649.504517,168.682190 652.012939,167.430191 
                C652.588928,167.142685 653.117371,166.760010 653.701477,166.400345 
                C653.031921,164.833710 651.570312,164.740433 650.415344,164.223557 
                C648.189636,163.227463 646.058228,161.981735 646.185974,159.249435 
                C646.312988,156.532135 648.601868,155.377548 650.828735,154.571869 
                C657.013062,152.334290 663.374268,151.330963 670.386841,152.336716 
            M468.009430,269.413239 
                C469.414917,270.422058 470.122528,272.407410 472.689575,272.575745 
                C472.689575,257.153015 472.535614,241.867935 472.783722,226.589386 
                C472.857849,222.024261 471.104431,220.936310 466.928680,220.988312 
                C452.937469,221.162552 438.942566,221.028137 424.949188,221.056442 
                C423.714050,221.058945 422.257660,220.558365 420.824066,222.720215 
                C436.325775,238.057861 451.924133,253.491104 468.009430,269.413239 
            M518.477661,264.976685 
                C532.556641,250.854538 546.635559,236.732361 560.714478,222.610199 
                C560.402161,222.128418 560.089783,221.646637 559.777466,221.164856 
                C543.995056,221.164856 528.212646,221.156235 512.430237,221.172119 
                C509.256500,221.175308 509.276917,223.468292 509.278564,225.725235 
                C509.284607,234.053101 509.281250,242.380951 509.281311,250.708817 
                C509.281372,257.975067 509.281281,265.241333 509.281281,273.879547 
                C512.830688,270.448303 515.403320,267.961304 518.477661,264.976685 
            z"/>
            </svg>
    `;
  };