import React, { useContext } from 'react';
import { Layout, Button } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';
import { AuthContext } from '../../context/AuthContext';

const { Header } = Layout;

const AppHeader = () => {
  const { logout } = useContext(AuthContext);

  return (
    <Header className="header">
      <div className="logo" />
      <h1 className="header-title">Lupa - Painel</h1>
      <Button 
        type="primary" 
        icon={<LogoutOutlined />} 
        onClick={logout} 
        className="logout-button"
      >
        Sair
      </Button>
    </Header>
  );
};

export default AppHeader;